import React, {useState, useEffect, useMemo} from "react";
import {
	Box,
	Flex,
	Icon,
	SimpleGrid,
	Spinner,
	Stat,
	StatLabel,
	StatNumber,
	Text,
} from "@chakra-ui/react";
import {DispenseHistory} from "./DispenseInterfaces";
import {useDispenseHistoryContext} from "../../context/DispenseHistoryContext";
import CustomizableTable from "../customizable-table/CustomizableTable";
import {User} from "../users/UserInterfaces";
import {WarningIcon} from "@chakra-ui/icons";
const dispenseHistoryFields: any = {
	id: {
		label: "ID",
		defaultVisible: true,
		disableFilter: true,
	},
	"user.username": {
		label: "User",
		defaultVisible: true,
		filterType: "string",
		disableFilter: true,
	},
	"user.email": {
		label: "Email",
		defaultVisible: true,
		filterType: "string",
		disableFilter: true,
	},
	"user.dni": {
		label: "DNI",
		defaultVisible: true,
		filterType: "string",
		disableFilter: true,
	},
	"product.Name": {
		label: "Product",
		defaultVisible: true,
		filterType: "string",
		disableFilter: true,
	},
	dateDispensed: {
		label: "Date Dispensed",
		defaultVisible: true,
		filterType: "dateRange",
	},
	units: {
		label: "Units",
		defaultVisible: true,
		filterType: "range",
		filterMin: 0,
		filterMax: 1000,
	},
	precio: {
		label: "Price",
		defaultVisible: true,
		filterType: "range",
		filterMin: 0,
		filterMax: 100000,
	},
	medioDePago: {
		label: "Payment Method",
		defaultVisible: true,
		filterType: "enum",
		filterOptions: ["MercadoPago", "Transferencia", "Cash"],
		formatter: (value: string) => value || 'N/A'
	},
};

const DispenseHistoriesView: React.FC = () => {
	const {dispenseHistories, loading, error} = useDispenseHistoryContext();
	const [filteredSortedData, setFilteredSortedData] = useState<
		DispenseHistory[]
	>([]);

	const totals = useMemo(() => {
		const totalSpent = filteredSortedData.reduce(
			(total, history) => total + history.precio,
			0
		);

		const cannabisData = filteredSortedData.filter(
			(history) =>
				history.product.productType.toLowerCase() === "cannabis" &&
				!history.product.Name.toLowerCase().includes("preroll")
		);
		const totalUnitsCannabis = cannabisData.reduce(
			(total, history) => total + history.units,
			0
		);
		const totalSpentCannabis = cannabisData.reduce(
			(total, history) => total + history.precio,
			0
		);

		const rosinData = filteredSortedData.filter(
			(history) => history.product.productType.toLowerCase() === "rosin"
		);
		const totalUnitsRosin = rosinData.reduce(
			(total, history) => total + history.units,
			0
		);
		const totalSpentRosin = rosinData.reduce(
			(total, history) => total + history.precio,
			0
		);

		const prerollsData = filteredSortedData.filter((history) =>
			history.product.Name.toLowerCase().includes("preroll")
		);
		const totalPrerolls = prerollsData.reduce(
			(total, history) => total + history.units,
			0
		);
		const totalSpentPrerolls = prerollsData.reduce(
			(total, history) => total + history.precio,
			0
		);

		const gummiesData = filteredSortedData.filter(
			(history) =>
				history.product.productType.toLowerCase() ===
					"derivado del cannabis" &&
				history.product.Name.toLowerCase().includes("gummies")
		);
		const totalGummies = gummiesData.reduce(
			(total, history) => total + history.units,
			0
		);
		const totalSpentGummies = gummiesData.reduce(
			(total, history) => total + history.precio,
			0
		);

		const porroCumpleanosData = filteredSortedData.filter(
			(history) =>
				history.product.productType.toLowerCase() ===
					"derivado del cannabis" &&
				history.product.Name.toLowerCase().includes(
					"porro de cumpleaños"
				)
		);
		const totalPorroCumpleanos = porroCumpleanosData.reduce(
			(total, history) => total + history.units,
			0
		);
		const totalSpentPorroCumpleanos = porroCumpleanosData.reduce(
			(total, history) => total + history.precio,
			0
		);

		return {
			totalSpent,
			totalUnitsCannabis,
			totalSpentCannabis,
			totalUnitsRosin,
			totalSpentRosin,
			totalPrerolls,
			totalSpentPrerolls,
			totalGummies,
			totalSpentGummies,
			totalPorroCumpleanos,
			totalSpentPorroCumpleanos,
		};
	}, [filteredSortedData]);

	const handleDataChange = (data: DispenseHistory[]) => {
		setFilteredSortedData(data);
	};

	// Transform dispense history data for the table
	const data = useMemo(() => dispenseHistories.map((history: DispenseHistory) => ({
		...history,
		dateDispensed: new Date(history.createdAt).getTime(),
		dateDispensed_formatted: new Date(history.createdAt).toLocaleDateString('en-US', {
			month: '2-digit',
			day: '2-digit',
			year: 'numeric',
			hour: '2-digit',
			minute: '2-digit',
			hour12: true
		})
	})), [dispenseHistories]);

	console.log('Table data:', data);

	if (loading) {
		return (
			<Box
				display="flex"
				justifyContent="center"
				alignItems="center"
				height="100vh"
				bg="gray.50"
			>
				<Box
					p={8}
					borderRadius="lg"
					bg="white"
					boxShadow="md"
					textAlign="center"
				>
					<Spinner
						thickness="4px"
						speed="0.65s"
						emptyColor="gray.200"
						color="green.500"
						size="xl"
						mb={4}
					/>
					<Text fontSize="lg" fontWeight="medium" color="gray.600">
						Loading dispense histories...
					</Text>
				</Box>
			</Box>
		);
	}

	if (error) {
		return (
			<Box
				display="flex"
				justifyContent="center"
				alignItems="center"
				height="100vh"
				bg="gray.50"
			>
				<Box
					p={8}
					borderRadius="lg"
					bg="white"
					boxShadow="md"
					textAlign="center"
				>
					<Icon
						as={WarningIcon}
						w={10}
						h={10}
						color="red.500"
						mb={4}
					/>
					<Text fontSize="lg" fontWeight="medium" color="red.600">
						Error: {error}
					</Text>
				</Box>
			</Box>
		);
	}

	return (
		<Box>
			<SimpleGrid columns={{base: 2, md: 3, lg: 6}} spacing={4} mb={8}>
				<Stat
					p={4}
					bg="white"
					borderRadius="lg"
					boxShadow="md"
					transition="all 0.3s"
					_hover={{transform: "translateY(-5px)", boxShadow: "lg"}}
				>
					<StatLabel
						fontSize="sm"
						fontWeight="medium"
						color="gray.500"
					>
						Total Spent
					</StatLabel>
					<StatNumber
						fontSize="md"
						fontWeight="bold"
						color="green.500"
					>
						{totals.totalSpent.toLocaleString("en-US", {
							style: "currency",
							currency: "ARS",
						})}
					</StatNumber>
				</Stat>
				<Stat
					p={4}
					bg="white"
					borderRadius="lg"
					boxShadow="md"
					transition="all 0.3s"
					_hover={{transform: "translateY(-5px)", boxShadow: "lg"}}
				>
					<StatLabel
						fontSize="sm"
						fontWeight="medium"
						color="gray.500"
					>
						Cannabis
					</StatLabel>
					<StatNumber
						fontSize="md"
						fontWeight="bold"
						color="purple.500"
					>
						{totals.totalUnitsCannabis.toFixed(2)}gr
					</StatNumber>
					<Text fontSize="sm" color="gray.500">
						{totals.totalSpentCannabis.toLocaleString("en-US", {
							style: "currency",
							currency: "ARS",
						})}
					</Text>
				</Stat>
				<Stat
					p={4}
					bg="white"
					borderRadius="lg"
					boxShadow="md"
					transition="all 0.3s"
					_hover={{transform: "translateY(-5px)", boxShadow: "lg"}}
				>
					<StatLabel
						fontSize="sm"
						fontWeight="medium"
						color="gray.500"
					>
						Rosin
					</StatLabel>
					<StatNumber
						fontSize="md"
						fontWeight="bold"
						color="yellow.500"
					>
						{totals.totalUnitsRosin.toFixed(2)}gr
					</StatNumber>
					<Text fontSize="sm" color="gray.500">
						{totals.totalSpentRosin.toLocaleString("en-US", {
							style: "currency",
							currency: "ARS",
						})}
					</Text>
				</Stat>
				<Stat
					p={4}
					bg="white"
					borderRadius="lg"
					boxShadow="md"
					transition="all 0.3s"
					_hover={{transform: "translateY(-5px)", boxShadow: "lg"}}
				>
					<StatLabel
						fontSize="sm"
						fontWeight="medium"
						color="gray.500"
					>
						Prerolls
					</StatLabel>
					<StatNumber
						fontSize="md"
						fontWeight="bold"
						color="blue.500"
					>
						{totals.totalPrerolls.toFixed(2)}
					</StatNumber>
					<Text fontSize="sm" color="gray.500">
						{totals.totalSpentPrerolls.toLocaleString("en-US", {
							style: "currency",
							currency: "ARS",
						})}
					</Text>
				</Stat>
				<Stat
					p={4}
					bg="white"
					borderRadius="lg"
					boxShadow="md"
					transition="all 0.3s"
					_hover={{transform: "translateY(-5px)", boxShadow: "lg"}}
				>
					<StatLabel
						fontSize="sm"
						fontWeight="medium"
						color="gray.500"
					>
						Gummies
					</StatLabel>
					<StatNumber
						fontSize="md"
						fontWeight="bold"
						color="pink.500"
					>
						{totals.totalGummies.toFixed(2)}
					</StatNumber>
					<Text fontSize="sm" color="gray.500">
						{totals.totalSpentGummies.toLocaleString("en-US", {
							style: "currency",
							currency: "ARS",
						})}
					</Text>
				</Stat>
				<Stat
					p={4}
					bg="white"
					borderRadius="lg"
					boxShadow="md"
					transition="all 0.3s"
					_hover={{transform: "translateY(-5px)", boxShadow: "lg"}}
				>
					<StatLabel
						fontSize="sm"
						fontWeight="medium"
						color="gray.500"
					>
						PDC
					</StatLabel>
					<StatNumber
						fontSize="md"
						fontWeight="bold"
						color="orange.500"
					>
						{totals.totalPorroCumpleanos.toFixed(2)}
					</StatNumber>
				</Stat>
			</SimpleGrid>
			<CustomizableTable<DispenseHistory>
				data={data}
				onDataChange={handleDataChange}
				fields={dispenseHistoryFields}
			/>
		</Box>
	);
};

export default DispenseHistoriesView;
