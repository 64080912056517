import React, {useEffect, useState, useCallback, useMemo} from "react";
import {
	Box,
	Table as ChakraTable,
	Thead,
	Tbody,
	Tr,
	Th,
	Td,
	IconButton,
	Input,
	InputGroup,
	InputLeftElement,
	Select,
	Flex,
	useDisclosure,
	Accordion,
	AccordionItem,
	AccordionButton,
	AccordionPanel,
	AccordionIcon,
} from "@chakra-ui/react";
import {
	ChevronLeftIcon,
	ChevronRightIcon,
	ChevronDownIcon,
	ChevronUpIcon,
	SettingsIcon,
	SearchIcon,
	ArrowLeftIcon,
	ArrowRightIcon,
} from "@chakra-ui/icons";
import ConfigurationPanel from "./ConfigurationPanel";
import AdvancedFilterInput from "./AdvancedFilterInput";
import {sortData} from "../../utils/tableUtils";
import {exportToCSV, exportToXLSX} from "../../utils/exportToCSV"; // Updated import
import DownloadButton from "./DownloadButton"; // Import the new component

export interface TableProps<T> {
	data: T[];
	fields: Record<
		keyof T,
		{
			label: string;
			defaultVisible?: boolean;
			filterField?: keyof T;
			disableFilter?: boolean;
		}
	>;
	onRowClick?: (item: T) => void;
	onDataChange?: (data: T[]) => void; // New callback prop
}

// Update the getNestedValue function to handle all date fields consistently
const getNestedValue = (obj: any, path: string) => {
	// Handle all date fields that need formatting
	if (path === "publishedAt" || path === "dateDispensed") {
		return obj[`${path}_formatted`] || "";
	}

	return (
		path.split(".").reduce((acc, part) => (acc ? acc[part] : ""), obj) || ""
	);
};

// Update the normalizeFilters function
const normalizeFilters = (
	filters: Record<string, any>
): Record<string, any> => {
	return Object.entries(filters).reduce(
		(acc: Record<string, any>, [key, value]) => {
			if (value?.start || value?.end) {
				acc[key] = {
					start: value.start ? new Date(value.start).getTime() : null,
					end: value.end
						? new Date(value.end).getTime() +
						  (24 * 60 * 60 * 1000 - 1)
						: null, // End of day
				};
			} else {
				acc[key] = value;
			}
			return acc;
		},
		{}
	);
};

// Update the filterData function
const filterData = (
	data: any[],
	filters: Record<string, any>,
	searchTerm: string
) => {
	return data.filter((item) => {
		// Check all filters
		for (const [field, value] of Object.entries(filters)) {
			if (!value) continue;

			// Handle date range filters
			if (value?.start || value?.end) {
				const itemDate = item[field];
				if (value.start && itemDate < value.start) return false;
				if (value.end && itemDate > value.end) return false;
				continue;
			}

			// Handle range filters
			if (value?.min !== undefined || value?.max !== undefined) {
				const itemValue = Number(getNestedValue(item, field));
				
				// When both min and max are 0, show items with value 0 or empty/null
				if (value.min === 0 && value.max === 0) {
					return itemValue === 0 || !itemValue;
				}

				// Apply min filter if set
				if (value.min !== undefined && itemValue < value.min) {
					return false;
				}
				
				// Apply max filter if set and not at max possible value
				if (value.max !== undefined && value.max !== 100000 && itemValue > value.max) {
					return false;
				}
				continue;
			}

			// Handle enum/string filters
			if (typeof value === "string") {
				const itemValue = String(
					getNestedValue(item, field)
				).toLowerCase();
				if (!itemValue.includes(value.toLowerCase())) return false;
				continue;
			}
		}

		// Handle global search
		if (searchTerm) {
			const searchLower = searchTerm.toLowerCase();
			return Object.keys(item).some((key) => {
				const value = getNestedValue(item, key);
				return String(value).toLowerCase().includes(searchLower);
			});
		}

		return true;
	});
};

const CustomizableTable = <T extends Record<string, any>>({
	data,
	fields,
	onRowClick,
	onDataChange,
}: TableProps<T>) => {
	const [visibleFields, setVisibleFields] = useState<(keyof T)[]>(
		() =>
			Object.keys(fields).filter(
				(key) => fields[key].defaultVisible !== false
			) as (keyof T)[]
	);
	const [tableState, setTableState] = useState({
		currentPage: 1,
		rowsPerPage: 10,
		sortField: "" as keyof T | "",
		sortOrder: "asc" as "asc" | "desc",
	});
	const [filterState, setFilterState] = useState({
		filters: {} as Partial<Record<keyof T, any>>,
		searchTerm: "",
	});
	const [debugLog, setDebugLog] = useState<any[]>([]);

	// Remove the separate memoization and simplify
	const filteredData = useMemo(() => {
		const normalizedFilters = normalizeFilters(filterState.filters);

		console.log("CustomizableTable Date Debug:", {
			platform: /Mobile/.test(navigator.userAgent) ? "mobile" : "desktop",
			dateFilter: filterState.filters.publishedAt,
			normalizedDateFilter: normalizedFilters.publishedAt,
			sampleData: data.slice(0, 1).map((item) => ({
				id: item.id,
				date: item.publishedAt,
			})),
		});

		const result = filterData(
			data,
			normalizedFilters,
			filterState.searchTerm
		);

		// Move logging outside the memo
		console.log("Filter Results:", {
			totalRows: data.length,
			filteredRows: result.length,
			filterApplied: result.length !== data.length,
		});

		return result;
	}, [data, JSON.stringify(filterState.filters), filterState.searchTerm]);

	const sortedData = useMemo(
		() =>
			sortData(
				filteredData,
				tableState.sortField as string,
				tableState.sortOrder
			),
		[filteredData, tableState.sortField, tableState.sortOrder]
	);

	const paginatedData = useMemo(() => {
		const startIndex =
			(tableState.currentPage - 1) * tableState.rowsPerPage;
		const endIndex = startIndex + tableState.rowsPerPage;
		return sortedData.slice(startIndex, endIndex);
	}, [sortedData, tableState.currentPage, tableState.rowsPerPage]);

	const {isOpen, onOpen, onClose} = useDisclosure();

	const handleFilterChange = useCallback((field: keyof T, value: any) => {
		setFilterState((prev) => {
			const newValue = value === "" ? null : value;
			if (
				JSON.stringify(prev.filters[field]) === JSON.stringify(newValue)
			) {
				return prev; // No change needed
			}
			return {
				...prev,
				filters: {
					...prev.filters,
					[field]: newValue,
				},
			};
		});
	}, []);

	const handleDateRangeFilterChange = useCallback(
		(field: keyof T, part: "start" | "end", date: Date | null) => {
			console.log("Date Range Change:", {
				field,
				part,
				date,
				isMobile: /Mobile/.test(navigator.userAgent),
				dateType: date ? typeof date : null,
				dateValue: date ? date.toISOString() : null,
				currentFilters: filterState.filters,
			});

			setFilterState((prevFilters) => {
				const existingRange = prevFilters.filters[field] || {
					start: null,
					end: null,
				};
				const updatedRange = {
					...existingRange,
					[part]: date
						? new Date(
								date.getTime() -
									date.getTimezoneOffset() * 60000
						  )
								.toISOString()
								.substring(0, 10)
						: null,
				};

				console.log("Updated Range:", {
					before: existingRange,
					after: updatedRange,
					isMobile: /Mobile/.test(navigator.userAgent),
				});

				return {
					...prevFilters,
					filters: {
						...prevFilters.filters,
						[field]:
							updatedRange.start || updatedRange.end
								? updatedRange
								: null,
					},
				};
			});
		},
		[]
	);

	useEffect(() => {
		if (onDataChange) {
			const timeoutId = setTimeout(() => {
				onDataChange(sortedData);
			}, 0);
			return () => clearTimeout(timeoutId);
		}
	}, [sortedData, onDataChange]);

	const handleFieldToggle = (field: keyof T) => {
		setVisibleFields((currentVisibleFields) => {
			if (currentVisibleFields.includes(field)) {
				return currentVisibleFields.filter((f) => f !== field);
			} else {
				return [...currentVisibleFields, field];
			}
		});
	};

	const handleSearch = useCallback((term: string) => {
		setFilterState((prev) => ({
			...prev,
			searchTerm: term,
		}));
		setTableState((prev) => ({
			...prev,
			currentPage: 1,
		}));
	}, []);

	const handlePageChange = (page: number) => {
		if (
			page >= 1 &&
			page <= Math.ceil(sortedData.length / tableState.rowsPerPage)
		) {
			setTableState((prev) => ({
				...prev,
				currentPage: page,
			}));
		}
	};

	const handleRowsPerPageChange = (value: string) => {
		setTableState((prev) => ({
			...prev,
			rowsPerPage: parseInt(value, 10),
		}));
		setTableState((prev) => ({
			...prev,
			currentPage: 1,
		}));
	};

	const handleDownload = (format: "csv" | "xlsx") => {
		const headers = visibleFields.map((field) => ({
			key: field,
			label: fields[field].label,
		}));
		if (format === "csv") {
			exportToCSV(sortedData, headers);
		} else {
			exportToXLSX(sortedData, headers);
		}
	};

	const totalPages = Math.ceil(sortedData.length / tableState.rowsPerPage);

	const handleSort = useCallback((field: keyof T) => {
		setTableState((prev) => ({
			...prev,
			sortField: field,
			sortOrder:
				prev.sortField === field && prev.sortOrder === "asc"
					? "desc"
					: "asc",
		}));
	}, []);

	return (
		<Box>
			<Flex justify="space-between" align="center" mb={4}>
				<InputGroup maxWidth="200px">
					<InputLeftElement pointerEvents="none">
						<SearchIcon color="gray.300" />
					</InputLeftElement>
					<Input
						placeholder="Search..."
						value={filterState.searchTerm}
						onChange={(e) => handleSearch(e.target.value)}
					/>
				</InputGroup>
				<Flex>
					<IconButton
						aria-label="Configure Table"
						icon={<SettingsIcon />}
						onClick={onOpen}
						mr={2}
					/>
					<DownloadButton onDownload={handleDownload} />
				</Flex>
			</Flex>
			<ConfigurationPanel
				isOpen={isOpen}
				onClose={onClose}
				fields={fields}
				visibleFields={visibleFields}
				setVisibleFields={setVisibleFields}
				handleFieldToggle={handleFieldToggle}
			/>
			<Accordion allowToggle>
				<AccordionItem>
					<h2>
						<AccordionButton bg={"gray.50"}>
							{" "}
							{/* Pale blue background on the accordion button */}
							<Box flex="1" textAlign="left">
								{" "}
								{/* Darker blue text for contrast */}
								Filters
							</Box>
							<AccordionIcon />
						</AccordionButton>
					</h2>
					<AccordionPanel pb={4} bg="gray.50">
						{" "}
						{/* Even paler blue for the panel background */}
						{Object.entries(fields)
							.filter(([_, config]) => !config.disableFilter)
							.map(([field, config]) => (
								<Box
									key={field}
									mb={2}
									p={2}
									bg="white"
									shadow="sm"
								>
									{" "}
									{/* White boxes with a slight shadow for each filter */}
									<AdvancedFilterInput
										field={field as keyof T}
										config={config}
										filters={filterState.filters}
										handleFilterChange={handleFilterChange}
										handleDateRangeFilterChange={
											handleDateRangeFilterChange
										}
									/>
								</Box>
							))}
					</AccordionPanel>
				</AccordionItem>
			</Accordion>

			<ChakraTable>
				<Thead>
					<Tr>
						{visibleFields.map((field) => (
							<Th
								key={field as string}
								cursor="pointer"
							>
								<Flex align="center" justify="space-between">
									<Box onClick={() => handleSort(field)}>{fields[field].label}</Box>
									{tableState.sortField === field && (
										<IconButton
											aria-label={`Sort ${tableState.sortOrder === "asc" ? "descending" : "ascending"}`}
											icon={tableState.sortOrder === "asc" ? <ChevronDownIcon /> : <ChevronUpIcon />}
											size="xs"
											ml={2}
											onClick={(e) => {
												e.stopPropagation();
												handleSort(field);
											}}
										/>
									)}
								</Flex>
							</Th>
						))}
					</Tr>
				</Thead>
				<Tbody>
					{paginatedData.map((item, index) => (
						<Tr
							key={index}
							onClick={() => onRowClick && onRowClick(item)}
						>
							{visibleFields.map((field) => (
								<Td key={field as string}>
									{getNestedValue(item, field as string)}
								</Td>
							))}
						</Tr>
					))}
				</Tbody>
			</ChakraTable>
			<Flex justify="space-between" align="center" mt={4}>
				<Select
					value={tableState.rowsPerPage}
					onChange={(e) => handleRowsPerPageChange(e.target.value)}
					width="auto"
				>
					<option value="10">10 rows</option>
					<option value="20">20 rows</option>
					<option value="50">50 rows</option>
				</Select>
				<Flex align="center">
					<IconButton
						aria-label="First Page"
						icon={<ArrowLeftIcon />}
						onClick={() => handlePageChange(1)}
						disabled={tableState.currentPage === 1}
						mr={2}
					/>
					<IconButton
						aria-label="Previous Page"
						icon={<ChevronLeftIcon />}
						onClick={() =>
							handlePageChange(tableState.currentPage - 1)
						}
						disabled={tableState.currentPage === 1}
						mr={2}
					/>
					<Box mx={2}>
						{tableState.currentPage} / {totalPages}
					</Box>
					<IconButton
						aria-label="Next Page"
						icon={<ChevronRightIcon />}
						onClick={() =>
							handlePageChange(tableState.currentPage + 1)
						}
						disabled={tableState.currentPage === totalPages}
						mr={2}
					/>
					<IconButton
						aria-label="Last Page"
						icon={<ArrowRightIcon />}
						onClick={() => handlePageChange(totalPages)}
						disabled={tableState.currentPage === totalPages}
					/>
				</Flex>
			</Flex>
		</Box>
	);
};

export default CustomizableTable;
